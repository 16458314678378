<template>
  <Select
    v-model="locale"
    :options="$i18n.availableLocales"
    :placeholder="$t('lang.chooseLanguage')"
    :transform="transformChoice"
    :size="size"
    basic
  />
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import Select from '@/components/atoms/Select.vue'

const transformChoice = getLocaleMessage => locale => ({
  value: locale,
  label: getLocaleMessage(locale).name,
})

export default defineComponent({
  components: {
    Select,
  },
  props: {
    size: {
      type: String,
      default: () => 'medium',
    },
  },
  data() {
    const { getLocaleMessage } = useI18n({
      useScope: 'global',
    })

    return {
      transformChoice: transformChoice(getLocaleMessage),
    }
  },
  computed: {
    locale: {
      get: function () {
        return this.$i18n.locale
      },
      set: function (value) {
        this.$store.dispatch('changeLanguage', value)
        this.$i18n.locale = value
      },
    },
  },
})
</script>
