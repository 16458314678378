<template>
  <page>
    <div class="h-screen flex flex-row items-stretch justify-start">
      <div class="hidden xl:block flex-1 bg-moutain bg-cover bg-center"></div>
      <div
        class="
          relative
          flex
          items-center
          justify-center
          flex-1
          xl:max-w-screen-sm
        "
      >
        <div class="flex flex-col items-center justify-center">
          <Logo full class="w-64 mb-32" />
          <el-button
            type="primary"
            size="medium"
            @click="login"
            class="text-xl font-bold font-montserrat"
          >
            {{ $t('login.signIn') }} / {{ $t('login.signUp') }}
          </el-button>
        </div>
        <div class="absolute bottom-5 left-0 right-0 text-center">
          <language-selector size="mini" />
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { defineComponent } from 'vue'
import { ElButton } from 'element-plus'

import Page from '@/components/templates/Page.vue'
import LanguageSelector from '@/components/organisms/LanguageSelector.vue'
import Logo from '@/components/atoms/Logo'

// import { afterLoginRoute } from '@/plugins/router'

export default defineComponent({
  name: 'LoginPage',
  components: {
    Page,
    LanguageSelector,
    Logo,
    ElButton,
  },
  methods: {
    async login() {
      this.$auth.loginWithRedirect()
    },
  },
  // watch: {
  //   $auth: {
  //     immedate: true,
  //     handler(auth) {
  //       console.log('auth', auth)
  //       // used for login with popup
  //       // prefer auth.user than auth.isAuthenticated because isAuthenticated return true by mistake
  //       if (auth.user.value) {
  //         this.$router.push(afterLoginRoute)
  //       }
  //     },
  //     deep: true,
  //   },
  // },
})
</script>
