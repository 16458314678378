<template>
  <img
    src="../../assets/rim-nat.png"
    alt="RIM-Nat"
    :class="className"
    v-if="full"
  />
  <img
    src="../../assets/rim-nat-logo.png"
    alt="RIM-Nat logo"
    :class="className"
    v-if="!full"
  />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Logo',
  props: {
    class: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    className: function () {
      return this.class
    },
  },
})
</script>
